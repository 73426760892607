import { Heading } from "@chakra-ui/react";

type Props = {
  children: string;
};

export const AppHeading = ({ children }: Props) => {
  return (
    <Heading
      fontSize={`20`}
      borderBottom={`3px`}
      borderBottomStyle={`solid`}
      borderColor="rgb(49, 130, 206)"
      pb={2}
      mb={3}
    >
      {children}
    </Heading>
  );
};
