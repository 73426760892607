import { Box } from "@chakra-ui/react";
import DatePicker from "react-datepicker";

import { AppHeading } from "../AppHeading";
import { useState } from "react";

type Props = {
  isStart?: boolean;
};

export const AppDate = ({ isStart = false }: Props) => {
  const [startDate, setStartDate] = useState<Date | null>(new Date());

  return (
    <Box>
      <AppHeading>{isStart ? "入庫日" : "出庫日"}</AppHeading>
      <DatePicker
        dateFormat="yyyy/MM/dd"
        locale="ja"
        selected={startDate}
        onChange={(date) => setStartDate(date)}
      />
    </Box>
  );
};
