import { Box, Heading, Stack, Text } from "@chakra-ui/react";

import { TaskListItem } from "./TaskListItem";
import { AppLink } from "../AppLink";
import { Buttons } from "./Buttons";

export const Tasks = () => {
  return (
    <Box>
      <Heading textAlign={`center`} fontSize={`25`} mb={5}>
        やることリスト
      </Heading>
      <Stack spacing={5}>
        <TaskListItem>
          <Text>
            <AppLink
              href={`https://haneda-p4.jp/airport/entrance/0000.jsf`}
              color={`darkcyan`}
              isExternal
            >
              P3駐車場
            </AppLink>{" "}
            の会員登録をする。
          </Text>
          <Text>
            <AppLink
              href={`https://haneda-p4.jp/airport/entrance/0000.jsf`}
              color={`darkcyan`}
              isExternal
            >
              P4駐車場
            </AppLink>{" "}
            の会員登録をする。
          </Text>
        </TaskListItem>
        <TaskListItem>
          <Text>
            2023年7月7日 AM0:00 に{" "}
            <AppLink
              href={`https://haneda-p4.jp/airport/entrance/0000.jsf`}
              color={`darkcyan`}
              isExternal
            >
              P4駐車場
            </AppLink>{" "}
            を予約。
          </Text>
          <Text>入庫日を 2023年7月7日 にする。</Text>
          <Text>出庫日を 2023年7月7日 にする。</Text>
        </TaskListItem>
        <TaskListItem>
          <Text>
            2023年7月7日 AM10:00 に{" "}
            <AppLink
              href={`https://hnd-rsv.aeif.or.jp/airport2/app/toppage`}
              color={`darkcyan`}
              isExternal
            >
              P3駐車場
            </AppLink>{" "}
            を予約
          </Text>
          <Text>入庫日を 2023年7月7日 にする。</Text>
          <Text>出庫日を 2023年7月7日 にする。</Text>
        </TaskListItem>
        <TaskListItem>
          <Text>
            2023年7月7日 AM10:00 に{" "}
            <AppLink
              href={`https://hnd-rsv.aeif.or.jp/airport2/app/toppage`}
              color={`darkcyan`}
              isExternal
            >
              P4駐車場
            </AppLink>{" "}
            の予約を変更。
          </Text>
          <Text>入庫日を 2023年7月7日 にする。</Text>
          <Text>出庫日を 2023年7月7日 にする。</Text>
        </TaskListItem>
        <TaskListItem>
          <Text>
            2023年7月7日 AM10:00 に{" "}
            <AppLink
              href={`https://hnd-rsv.aeif.or.jp/airport2/app/toppage`}
              color={`darkcyan`}
              isExternal
            >
              P3駐車場
            </AppLink>{" "}
            の予約を変更。
          </Text>
          <Text>入庫日を 2023年7月7日 にする。</Text>
          <Text>出庫日を 2023年7月7日 にする。</Text>
        </TaskListItem>
        <TaskListItem>
          <Text>
            2023年7月7日 AM10:00 に{" "}
            <AppLink
              href={`https://hnd-rsv.aeif.or.jp/airport2/app/toppage`}
              color={`darkcyan`}
              isExternal
            >
              P4駐車場
            </AppLink>{" "}
            の予約を変更。
          </Text>
          <Text>入庫日を 2023年7月7日 にする。</Text>
          <Text>出庫日を 2023年7月7日 にする。</Text>
        </TaskListItem>
        <TaskListItem>
          <Text>
            2023年7月7日 AM10:00 に{" "}
            <AppLink
              href={`https://hnd-rsv.aeif.or.jp/airport2/app/toppage`}
              color={`darkcyan`}
              isExternal
            >
              P3駐車場
            </AppLink>{" "}
            の予約を変更。
          </Text>
          <Text>入庫日を 2023年7月7日 にする。</Text>
          <Text>出庫日を 2023年7月7日 にする。</Text>
        </TaskListItem>
        <TaskListItem>
          <Text>
            P3とP4の両方の予約が取れた場合はどちらかをキャンセルする。
          </Text>
        </TaskListItem>
        <Buttons />
      </Stack>
    </Box>
  );
};
