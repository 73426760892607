import { Button, HStack } from "@chakra-ui/react";

export const Buttons = () => {
  return (
    <HStack justifyContent={`center`}>
      <Button colorScheme="green">LINEでシェアする</Button>
      <Button colorScheme="blue">URLをコピーする</Button>
    </HStack>
  );
};
