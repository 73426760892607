import { Box, Radio, RadioGroup, Stack } from "@chakra-ui/react";
import { AppHeading } from "../AppHeading";

export const SelectTerminal = () => {
  return (
    <Box>
      <AppHeading>利用するターミナル</AppHeading>
      <RadioGroup defaultValue={`1`}>
        <Stack>
          <Radio value={`1`}>第1ターミナル(JAL/JTA/SKY/SFJ)</Radio>
          <Radio value={`2`}>第2ターミナル(ANA/ADO/SNA)</Radio>
        </Stack>
      </RadioGroup>
    </Box>
  );
};
