import { Box, Container, ChakraProvider, extendTheme } from "@chakra-ui/react";

import { Reservation } from "./Reservation";
import { Description } from "./Description";
import { Title } from "./Title";
import { Footer } from "./Footer";
import { Tasks } from "./Tasks";

function App() {
  const theme = extendTheme({
    styles: {},
  });

  return (
    <ChakraProvider theme={theme}>
      <Container
        sx={{
          display: "grid",
          gridTemplateRows: "auto 1fr auto",
          height: "100vh",
        }}
      >
        <Title />
        <Box>
          <Description />
          <Reservation />
          <Tasks />
        </Box>
        <Footer />
      </Container>
    </ChakraProvider>
  );
}

export default App;
