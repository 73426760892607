import { Text, Box } from "@chakra-ui/react";
import { AppLink } from "./AppLink";

export const Description = () => {
  return (
    <Box>
      <Text>
        羽田空港の駐車場を予約するのはかなり難しく、公式サイトに書いてある「30日前の午前10:00」から予約しようと思ってもすでに埋まっている可能性が高いです。公式サイトでも以下のように記載されています。
      </Text>
      <Text my={2}>
        <AppLink href="https://www.aeif.or.jp/haneda/" isExternal>
          羽田空港駐車場-空港ビル直結の便利な駐車場
        </AppLink>
      </Text>
      <Text
        as={`blockquote`}
        my={3}
        sx={{
          fontWeight: "bold",
          fontStyle: "italic",
        }}
      >
        予約は30日前の午前10時から可能ですが、すでに予約を取られている方がこの日を含めた先の日までの予約を入れているため、当日午前10時の段階で受付できる予約台数が極端に少なくなっている場合がございます。予めご了承ください。
      </Text>
      <Text>
        このジェネレータは<strong>いつ予約するべきか？</strong>
        等の確実に予約を取るための「やることリスト」をジェネレートします。
      </Text>
    </Box>
  );
};
