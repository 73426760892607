import { Box } from "@chakra-ui/react";
import { ReactNode } from "react";

type Props = {
  children: ReactNode | string;
};

export const TaskListItem = ({ children }: Props) => {
  return (
    <Box p={5} bgColor={`gray.200`} borderRadius={8}>
      {children}
    </Box>
  );
};
