import { Box, Divider, Text } from "@chakra-ui/react";

export const Footer = () => {
  return (
    <Box mt={10}>
      <Divider />
      <Box py={5}>
        <Text fontSize={`12`} textAlign={`center`}>
          Copyright © 2023 株式会社トゥーアール All Rights Reserved.
        </Text>
      </Box>
    </Box>
  );
};
