import { Link, LinkProps } from "@chakra-ui/react";

type Props = LinkProps;

export const AppLink = ({ children, ...rest }: Props) => {
  return (
    <Link {...rest} color={"blue.500"}>
      {children}
    </Link>
  );
};
