import { Box, Button, Stack } from "@chakra-ui/react";
import { SelectTerminal } from "./SelectTerminal";
import { AppDate } from "./AppDate";
import { registerLocale } from "react-datepicker";

import ja from "date-fns/locale/ja";

import "react-datepicker/dist/react-datepicker.css";
import "./data-picker.css";

registerLocale("ja", ja);

export const Reservation = () => {
  return (
    <Box my={10}>
      <form>
        <Stack spacing={7}>
          <SelectTerminal />
          <AppDate isStart />
          <AppDate />
          <Button type="submit" colorScheme="blue">
            決定
          </Button>
        </Stack>
      </form>
    </Box>
  );
};
